import WebHttp, {
  WEB_HTTP_CONTEXT,
  WebHttpAxiosConfig,
  WebHttpConfig
} from '@am92/web-http'
import {
  API_DOMAIN,
  API_KEY,
  ENABLE_CRYPTOGRAPHY,
  API_TIMEOUT,
  AUTHORIZATION,
  X_API_CLIENT_ID,
  X_SERVICE_NAME,
  X_SourceChannel
} from './env'

const AS_AXIOS_HTTP_CONFIG: WebHttpAxiosConfig = {
  baseURL: API_DOMAIN,
  timeout: Number(API_TIMEOUT),
  retries: 2,
  headers: {
    authorization: AUTHORIZATION,
    'X-SERVICE-NAME': X_SERVICE_NAME,
    'X-SourceChannel': X_SourceChannel
  }
}
const AS_WEB_HTTP_CONFIG: WebHttpConfig = {
  disableCrypto: ENABLE_CRYPTOGRAPHY === 'false',
  disableHeaderInjection: false
}
const asHttp = new WebHttp(AS_AXIOS_HTTP_CONFIG, AS_WEB_HTTP_CONFIG)
asHttp.context.set(WEB_HTTP_CONTEXT.API_KEY, API_KEY || '')
asHttp.context.set(WEB_HTTP_CONTEXT.CLIENT_ID, X_API_CLIENT_ID || '')

export { asHttp }
export const HANDSHAKE_ENABLED_HTTP: WebHttp[] = [asHttp]
