import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'

export const updateRequestIdAction = createAction<string, string>(
  'securities/updateRequestId'
)

export const updateRequestStatusAction = createAction<string, string>(
  'securities/updateRequestStatus'
)

export const updateMetaDataForInitiatorAction = createAction<any, string>(
  'securities/updateMetaDataForInitiator'
)

//SSO
export const validateSSOServiceName = 'securities/validateSSO'
export const validateSSOActions = traceActionsCreator(validateSSOServiceName)

export const initiateSSOServiceName = 'securities/initiateSSO'
export const initiateSSOActions = traceActionsCreator(initiateSSOServiceName)

export const completeSSOServiceName = 'securities/completeSSO'
export const completeSSOActions = traceActionsCreator(completeSSOServiceName)

//Depository
export const createLinkServiceName = 'securities/createLink'
export const createLinkActions = traceActionsCreator(createLinkServiceName)

export const getStatusServiceName = 'securities/getStatus'
export const getStatusActions = traceActionsCreator(getStatusServiceName)

//Redirect To Login
export const redirectToLoginServiceName = 'securities/redirectToLogin'
export const redirectToLoginActions = traceActionsCreator(
  redirectToLoginServiceName
)
