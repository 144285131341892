import { DsBox } from '@am92/react-design-system'
import React from 'react'
import SomethingWentWrongError from '~/src/Pages/SomethingWentWrongError/SomethingWentWrongError.Page'

export const ErrorBoundary = () => {
  return (
    <DsBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: {
          md: 'center',
          sx: 'start'
        },
        height: '100vh'
      }}
    >
      <DsBox
        sx={{
          width: { md: 'auto', xs: '100%' },
          border: {
            md: '1px solid var(--ds-colour-strokeDefault)',
            sx: 'none'
          },
          borderRadius: 'var(--ds-spacing-glacial)',
          padding: 'var(--ds-spacing-mild)'
        }}
      >
        <SomethingWentWrongError />
      </DsBox>
    </DsBox>
  )
}
