import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

// utils
import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'

// actions
import { setErrorAction } from '../Redux/Error/Actions'

// constants
import { getErrorObj } from '../Constants/ERROR_MAP'
import { getErrorObjSelector } from '../Redux/Error/Selectors'
import APP_ROUTES from '../Constants/APP_ROUTES'

type ActionTypes = {
  setError: (errorObj: any) => any
}

export interface IErrorNotifierHandlerProps extends IWithRouterProps {
  errorObj: any
  actions: ActionTypes
}

export default function withErrorConnect(
  mapStateToProps: (state: any) => any | null | undefined,
  mapDispatchToProps: (state: any) => any | null | undefined
) {
  const mapStateToPropsWrapper = (state: any) => {
    const componentState = mapStateToProps && mapStateToProps(state)
    const errorObj = getErrorObjSelector(state)
    return {
      errorObj,
      ...componentState
    }
  }

  const mapDispatchToPropsWrapper = (dispatch: any) => {
    const componentActions = mapDispatchToProps && mapDispatchToProps(dispatch)
    return {
      actions: {
        setError: (errorObj: any) => dispatch(setErrorAction(errorObj)),
        ...componentActions.actions
      }
    }
  }

  return function withErrorNotifierHandler(
    WrappedComponent: React.ElementType<any>
  ) {
    class ErrorNotifierHandler extends PureComponent<IErrorNotifierHandlerProps> {
      formRef = React.createRef<any>()

      handleError = async (res: any) => {
        const { actions, navigateTo } = this.props
        const { error = {} } = res
        const { code = null } = error
        const errorObj = getErrorObj(code)
        if (errorObj) {
          actions.setError(errorObj)
        }
        navigateTo(APP_ROUTES.SOMETHING_WENT_WRONG.pathname)
      }

      render() {
        return (
          <>
            <WrappedComponent
              {...this.props}
              formRef={this.formRef}
              handleError={this.handleError}
            />
          </>
        )
      }
    }

    return withRouter(
      connect(
        mapStateToPropsWrapper,
        mapDispatchToPropsWrapper
      )(ErrorNotifierHandler)
    )
  }
}
