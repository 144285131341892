import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import { setErrorAction, clearErrorAction } from './Actions'

const INITIAL_STATE = {
  error: null
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(setErrorAction, (state, { payload }) => {
      state.error = payload
    })

    builder.addCase(clearErrorAction, (state, { payload }) => {
      state.error = INITIAL_STATE
    })
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
