import { combineReducers, ReducersMapObject } from 'redux'

import AuthReducer from './Auth/Reducer'
import { SLICE_NAME as AuthSliceName } from './Auth/Selectors'

import SecuritiesReducer from './Securities/Reducer'
import { SLICE_NAME as SecuritiesSliceName } from './Securities/Selectors'

import ServiceTrackerReducer from './ServiceTracker/Reducer'
import { SLICE_NAME as ServiceTrackerSliceName } from './ServiceTracker/Selectors'

import ErrorReducer from './Error/Reducer'
import { SLICE_NAME as ErrorSliceName } from './Error/Selectors'

import ThemeReducer from './Theme/Reducer'
import { SLICE_NAME as ThemeSliceName } from './Theme/Selectors'

const reducers: ReducersMapObject = {
  [ThemeSliceName]: ThemeReducer,
  [ErrorSliceName]: ErrorReducer,
  [AuthSliceName]: AuthReducer,
  [SecuritiesSliceName]: SecuritiesReducer,
  [ServiceTrackerSliceName]: ServiceTrackerReducer
}

const persistedReducers: any[] = []

export default combineReducers(reducers)
export { persistedReducers }
