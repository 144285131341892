import { To } from 'react-router'
import { TECH_FAIL_ASSEST } from './ASSEST_MAP'

type ErrorCodes =
  | 100
  | 1001
  | 1002
  | 1003
  | 1004
  | 1005
  | 1006
  | 1007
  | 1008
  | 1009
  | 1010
  | 1011
  | 1012
  | 1013
  | 1014
  | 1015
  | 1016
  | 1017
  | 1018
  | 1019
  | 1020
  | 1021
  | 1022
  | 1023
  | 1024
  | 1025
  | 1026
  | 1027
  | 1028
  | 1029
  | 1030
  | 1031
  | 1032
  | 1033
  | 1034
  | 1035
  | 1036
  | 1037
  | 1038
  | 1039
  | 1040
  | 1041
  | 1042
  | 1043
  | 1044
  | 1045
  | 1046
  | 1047
  | 1048
  | 1049
  | 1050
  | 1051
  | 1052
  | 1053
  | 1054
  | 1055
  | 1056
  | 1057
  | 1058
  | 1059
  | 1060
  | 1061
  | 1062
  | 1063
  | 1064
  | 1065
  | 1066
  | 1067
  | 1068
  | 1069
  | 1070
  | 1071
  | 1072
  | 1073
  | 1074
  | 1075
  | 1076
  | 1077
  | 1078
  | 1079
  | 1080
  | 1081
  | 1082
  | 1083
  | 1084
  | 1085
  | 1086
  | 1087
  | 1088
  | 1089
  | 1090
  | 1091
  | 1092
  | 1093
  | 1094
  | 1095
  | 1096
  | 9999
  | 9998

type ErrorMap = {
  [key in ErrorCodes]: any
}

export interface Error {
  type?: 'NOTISTACK' | 'NOTISTACK_NAVIGATION' | 'NAVIGATION'
  errorCode: number
  notification?: {
    message: string
  }
  navigation?: {
    pathname: To
  }
  topLogo?: {
    src: string
    alt: string
    as: string
  }[]
  heading: string[]
  subHeading: string[]
}

const ERROR_MAP: { [key: number]: Error } = {
  1080: {
    errorCode: 1080,
    topLogo: TECH_FAIL_ASSEST,
    heading: ['Session was Unsuccessful'],
    subHeading: [
      'Oops! It seems like your current session was unsuccessful. Either the data was',
      'incomplete or invalid. Please try again.'
    ]
  },

  1081: {
    errorCode: 1081,
    topLogo: TECH_FAIL_ASSEST,
    heading: ['Session Expired!'],
    subHeading: [
      'Oops! It seems like your current session was expired. Please do not close',
      'this window while we redirect you.'
    ]
  },

  1083: {
    errorCode: 1083,
    topLogo: TECH_FAIL_ASSEST,
    heading: ['Something Went Wrong'],
    subHeading: [
      "We're trying to figure out this issue. Please close this tab and try again."
    ]
  },

  2000: {
    errorCode: 2000,
    topLogo: TECH_FAIL_ASSEST,
    heading: ['Something Went Wrong'],
    subHeading: [
      'We’re trying to figure out this issue. Please do not close this window while',
      'we redirect you.'
    ]
  }
}

export const getErrorObj = (errorCode: ErrorCodes) => {
  return ERROR_MAP[errorCode]
}
