import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import {
  validateSSOActions,
  completeSSOActions,
  getStatusActions,
  initiateSSOActions,
  updateRequestIdAction,
  updateRequestStatusAction,
  updateMetaDataForInitiatorAction
} from './Actions'

const INITIAL_STATE = {
  isValidated: false,
  requestId: '',
  requestStatus: '',
  ssoValidationResponse: {
    sessionId: '',
    refreshToken: null,
    authToken: null,
    metadata: null
  },
  ssoCompleteResponse: { redirectURL: '' },
  statusData: { subAccountId: '', status: '', securities: null },
  metaDataForInitiator: {
    actionType: 'SECURITIES'
  }
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(updateRequestIdAction, (state, { payload }) => {
      state.requestId = payload
    })
    builder.addCase(updateRequestStatusAction, (state, { payload }) => {
      state.requestStatus = payload
    })
    builder.addCase(updateMetaDataForInitiatorAction, (state, { payload }) => {
      state.metaDataForInitiator = {
        ...state.metaDataForInitiator,
        ...payload
      }
    })
    builder.addCase(validateSSOActions.success, (state, { payload }) => {
      state.ssoValidationResponse = payload.data
      state.isValidated = true
    })
    builder.addCase(initiateSSOActions.success, (state, { payload }) => {
      state.ssoInitiateResponse = payload.data
    })
    builder.addCase(completeSSOActions.success, (state, { payload }) => {
      state.ssoCompleteResponse = payload.data
    })
    builder.addCase(getStatusActions.success, (state, { payload }) => {
      state.statusData = payload.data
    })
  }
}

const slice = createSlice(sliceOptions)
export default slice.reducer
