import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'securities'

const select = (state: any) => state[SLICE_NAME]
const isLoggedInSelect = (state: any) => state[SLICE_NAME].isLoggedIn
const accessTokenSelect = (state: any) => state[SLICE_NAME].accessToken
const refreshTokenSelect = (state: any) => state[SLICE_NAME].refreshToken

export const isValidated = createSelector(
  select,
  securities => securities.isValidated
)

export const getSsoValidateResponse = createSelector(
  select,
  securities => securities.ssoValidationResponse
)

export const getRequestId = createSelector(
  select,
  securities => securities.requestId
)

export const getRequestStatus = createSelector(
  select,
  securities => securities.requestStatus
)
