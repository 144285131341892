export const DsAccordionDetailsOverrides = {
    MuiAccordionDetails: {
        styleOverrides: {
            root: {
                border: 'none',
                paddingTop: 'var(--ds-spacing-mild)',
                paddingBottom: 'var(--ds-spacing-mild)',
                paddingLeft: 'var(--ds-spacing-bitterCold)',
                paddingRight: 'var(--ds-spacing-bitterCold)'
            }
        }
    }
};
