import { CreateSliceOptions, createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Selectors";
import { changeThemeModeAction } from "./Actions";

type INITIAL_STATE_TYPE = { mode: string };
const INITIAL_STATE = { mode: "light" };

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    changeTheme: (state: INITIAL_STATE_TYPE, { payload }) => {
      state.mode = payload.themeMode;
    },
  },
  extraReducers: {
    [changeThemeModeAction as any]: (state, { payload }) => {
      state.mode = payload.themeMode;
    },
  },
};

const slice = createSlice(sliceOptions);
export const { changeTheme } = slice.actions;
export default slice.reducer;
