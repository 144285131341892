import React from 'react'
import {
  DsBox,
  DsImage,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import { PureComponent } from 'react'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { getErrorObjSelector } from '~/src/Redux/Error/Selectors'
import { getErrorObj } from '~/src/Constants/ERROR_MAP'
import { getSsoValidateResponse } from '~/src/Redux/Securities/Selectors'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'

const REDIRECT_TO_LOGIN_ERROR_CODES = [1080, 1081, 1083, '1080', '1081', '1083']
export interface ISomethingWentWrongErrorProps extends IWithRouterProps {
  errorObj: any
  ssoValidateResponse: any
}
class SomethingWentWrongError extends PureComponent<ISomethingWentWrongErrorProps> {
  componentDidMount(): void {
    setTimeout(() => {
      this.redirectToLogin()
    }, 1000)
  }

  redirectToLogin = () => {
    const { ssoValidateResponse, errorObj, navigateTo } = this.props
    const {
      metadata = null,
      refreshToken = null,
      authToken = null
    } = ssoValidateResponse || {}
    const { errorCode = '' } = errorObj || {}
    const { subAccountId = '', redirectionURL = '' } = metadata || {}

    if (metadata && refreshToken && authToken) {
      const { source = '' } = metadata
      if (source === 'web' || source === 'native') {
        if (
          !REDIRECT_TO_LOGIN_ERROR_CODES.includes(errorCode) &&
          subAccountId
        ) {
          setTimeout(() => {
            navigateTo(APP_ROUTES.REDIRECT_TO_INITIATOR.pathname)
          }, 1000)
          return
        }
        if (source === 'web' && redirectionURL) {
          setTimeout(() => {
            navigateTo(APP_ROUTES.REDIRECT_TO_LOGIN.pathname)
          }, 2000)
        }
      }
    }
  }

  renderHeadingDescriptions = (heading: string[]) => {
    return (
      <DsBox>
        {heading.map((heading: string, index: number) => {
          return (
            <DsTypography
              key={index}
              variant="headingBoldLarge"
              component={'div'}
            >
              {heading}
            </DsTypography>
          )
        })}
      </DsBox>
    )
  }

  renderSubHeadingDescriptions = (subHeading: string[]) => {
    if (!subHeading) return null
    return (
      <DsBox
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <DsBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center'
          }}
        >
          {subHeading.map((text: string, index: number) => (
            <DsTypography
              key={index}
              variant={'bodyRegularLarge'}
              sx={{
                color: 'var(--ds-colour-typoSecondary)'
              }}
            >
              {text}
            </DsTypography>
          ))}
        </DsBox>
      </DsBox>
    )
  }

  render() {
    const { ssoValidateResponse, errorObj } = this.props
    const {
      metadata = null,
      refreshToken = null,
      authToken = null
    } = ssoValidateResponse || {}
    const {
      subAccountId = '',
      source = '',
      from = '',
      type = '',
      redirectionURL = ''
    } = metadata || {}
    let { errorCode = 1083 } = errorObj || {}
    if (errorCode === 1083 && refreshToken && authToken && metadata && source) {
      errorCode = 2000
      if (!subAccountId && !redirectionURL) {
        errorCode = 1083
      }
    }
    const error = getErrorObj(errorCode)
    const { topLogo, heading, subHeading } = error
    return (
      <DsStack
        spacing="var(--ds-spacing-frostbite)"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          height: '100%'
        }}
      >
        <DsBox>
          <DsImage srcSet={topLogo} />
        </DsBox>
        {this.renderHeadingDescriptions(heading)}
        {this.renderSubHeadingDescriptions(subHeading)}
      </DsStack>
    )
  }
}

const mapStateToProps = (state: any) => {
  const errorObj = getErrorObjSelector(state)
  const ssoValidateResponse = getSsoValidateResponse(state)
  return {
    errorObj,
    ssoValidateResponse
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(SomethingWentWrongError)
