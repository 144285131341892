export const NODE_ENV = process.env.NODE_ENV
export const BABEL_ENV = process.env.BABEL_ENV

export const APP_NAME = process.env.APP_NAME
export const APP_TITLE = process.env.APP_TITLE || ''
export const APP_VERSION = process.env.APP_VERSION
export const APP_DESCRIPTION = process.env.APP_DESCRIPTION

export const SELF_DOMAIN = process.env.SELF_DOMAIN
export const SERVICE_DOMAIN = process.env.SERVICE_DOMAIN
export const ASSET_DOMAIN = process.env.ASSET_DOMAIN
export const APP_PRECONNECT_DOMAINS = process.env.APP_PRECONNECT_DOMAINS

export const ENABLE_CRYPTOGRAPHY = process.env.ENABLE_CRYPTOGRAPHY

export const API_KEY = process.env.API_KEY
export const API_TIMEOUT = process.env.API_TIMEOUT
export const API_DOMAIN = process.env.API_DOMAIN
export const HANDSHAKE_URL = process.env.HANDSHAKE_URL
export const AUTHORIZATION = process.env.AUTHORIZATION
export const API_ENCRYPTION_KEY = process.env.API_ENCRYPTION_KEY
export const X_API_CLIENT_ID = process.env.X_API_CLIENT_ID
export const X_SERVICE_NAME = process.env.X_SERVICE_NAME
export const X_SourceChannel = process.env.X_SourceChannel
