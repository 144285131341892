import { NavigateOptions, To } from 'react-router-dom'

type AppRouteItem = {
  pathname: To
  options?: NavigateOptions
}

const APP_ROUTES = {
  REQUEST_TO_DEPOSITORY: {
    pathname: '/request-to-depository'
  } as AppRouteItem,
  SHOW_REQUEST_STATUS: {
    pathname: '/show-request-status'
  } as AppRouteItem,
  VALIDATE_SSO: {
    pathname: '/'
  } as AppRouteItem,
  REDIRECT_TO_LOGIN: {
    pathname: '/redirect-to-login'
  } as AppRouteItem,
  SERVICE_NOT_AVAILABLE: {
    pathname: '/service-not-available'
  } as AppRouteItem,
  REDIRECT_TO_INITIATOR: {
    pathname: '/redirect-to-initiator'
  } as AppRouteItem,
  SOMETHING_WENT_WRONG: {
    pathname: '/something-went-wrong'
  } as AppRouteItem,
  ANY: {
    pathname: '*'
  } as AppRouteItem
}

export default APP_ROUTES
