import { DsBackdrop, DsCircularProgress } from '@am92/react-design-system'
import React, { PureComponent } from 'react'

export default class Loader extends PureComponent {
  render() {
    return (
      <DsBackdrop open invisible>
        <DsCircularProgress />
      </DsBackdrop>
    )
  }
}
