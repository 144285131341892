import * as React from 'react'

import { DsBox } from '@am92/react-design-system'
import { Outlet } from 'react-router-dom'

export interface IValidationLayoutProps {}

export default class ValidationLayout extends React.Component<IValidationLayoutProps> {
  render() {
    return (
      <DsBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: {
            md: 'center',
            sx: 'start'
          },
          height: '100vh'
        }}
      >
        <DsBox
          sx={{
            width: { md: 'auto', xs: '100%' },
            border: {
              md: '1px solid var(--ds-colour-strokeDefault)',
              sx: 'none'
            },
            borderRadius: 'var(--ds-spacing-glacial)',
            padding: 'var(--ds-spacing-mild)'
          }}
        >
          <Outlet />
        </DsBox>
      </DsBox>
    )
  }
}
