export const SOMETHING_WENT_WRONG_ASSEST = [
  {
    src: new URL(
      '~/public/assets/images/somethingWentWrong.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const REQUEST_PENDING_ASSEST = [
  {
    src: new URL('~/public/assets/images/request_pending.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const BULB_ASSEST = [
  {
    src: new URL('~/public/assets/images/bulb.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const SUCCESS_ASSEST = [
  {
    src: new URL('~/public/assets/images/success.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const REDIRECT_ASSEST = [
  {
    src: new URL('~/public/assets/images/redirecting.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const TECH_FAIL_ASSEST = [
  {
    src: new URL('~/public/assets/images/techFail.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const AUTH_FAIL_ASSEST = [
  {
    src: new URL('~/public/assets/images/authFail.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const NSDL_REDIRECTION = [
  {
    src: new URL('~/public/assets/images/nsdl_redirection.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const SERVICE_NOT_AVAILABLE = [
  {
    src: new URL(
      '~/public/assets/images/service_not_available.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const LINK_TO_DDPI = [
  {
    src: new URL('~/public/assets/images/linkToDdpi.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const PERCENTAGE = [
  {
    src: new URL('~/public/assets/images/percentage.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const LOCK = [
  {
    src: new URL('~/public/assets/images/lock.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const INSTANT = [
  {
    src: new URL('~/public/assets/images/instantly.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
